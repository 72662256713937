angular.module("labApp").controller('ResultsFeedController', function (
    $scope, $rootScope, $resource, LabResultsSummaryResource, $khMessage,
    LabPermissionsService,
    SelectedTeamService) {

    $rootScope.page_id = "labanalytics";
    $rootScope.sub_app_page_id = null;
    $rootScope.currentNavItem = null;
    $scope.checkPermission = LabPermissionsService.checkPermission;
    $scope.checkIfStorage = checkIfStorage;

    var subs = [
        SelectedTeamService.getTeamSlug$().subscribe(refresh)
    ];

    function refresh() {
        var teamFilter = { team_slug: SelectedTeamService.getSelectedSlug() };
        $scope.results = [];
        $scope.noResultsMessage = "Fetching assessments";
        LabResultsSummaryResource.query(teamFilter,
            function(resp) {
                $scope.results = resp;
                $scope.noResultsMessage = 'No assessments have been recorded on new samples over the past 10 days';
            }
        );
    }


    function checkIfStorage(input) {
        return (input === 'storage');
    }
});

