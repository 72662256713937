angular.module('labApp').component('sampleTableActions', {
    bindings: {
        service: '<',
        sample: '<',
        type: '@'
    },
    templateUrl: '/static/assets/khapps/lab/samples_and_results/sample_list/sample-table-actions.component.html',
    controller: function ($mdDialog, FieldStoreSamplesService) {
        var self = this;
        self.recordNewResult = function () {
            $mdDialog.show(
                {
                    templateUrl: '/static/assets/khapps/lab/samples_and_results/assessment_result_add_edit/AssessmentResultForm.html',
                    controller: 'AssessmentResultAddController',
                    locals: {
                        sample_slug: self.sample.sample_slug
                    }
                }
            ).then(function() {
                FieldStoreSamplesService.reload();
            })
        };
        self.deleteSample = function() {
            self.service.deleteSample(self.type, self.sample.sample_slug);
        }
    }
});