(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentResultEditController', [
            '$rootScope',
            '$scope',
            '$location',
            '$routeParams',
            '$timeout',
            'AssessmentFactory',
            'ResultFactory',
            'NewResultsHelpersFactory',
            '$khMessage',
            '$filter',
            '$mdDialog',
            'sample_slug',
            'result_slug',
            'fileUploads',
            function ($rootScope,
                      $scope,
                      $location,
                      $routeParams,
                      $timeout,
                      AssessmentFactory,
                      ResultFactory,
                      NewResultsHelpersFactory,
                      $khMessage,
                      $filter,
                      $mdDialog,
                      sample_slug,
                      result_slug,
                      fileUploads) {

                $scope.edit = true;
                $scope.dialogTitle = $filter('translate')('edit_results');
                $scope.loadingFieldsStatus = false;
                $scope.sample_slug = sample_slug;
                $scope.result_slug = result_slug;
                $scope.closeDialog = closeDialog
                $scope.fileuploadsconfig = {
                    show_files: true,
                    app_tag: 'LabAnalytics',
                    max_files: 6,
                    db_type: 'mongo',
                    display_type: 'detailed',
                    allow_duplicates: true
                };
                $scope.fields = [];
                $scope.model = {};
                $scope.options = {};

                ResultFactory.get(
                    $scope.result_slug,
                    {},
                    function (success) {
                        $scope.success = success;
                        $scope.results = createResultsDict($scope.success.results.assessment_values);
                        getAttachments();
                        var params = _.sortBy(success.results.assessment_config_ref.parameters_ref_list,
                            function(param) {
                                return param.form_position;
                            });
                        $scope.fieldString = NewResultsHelpersFactory.createForm(params, 'edit', $scope.results);
                        $scope.fields = eval($scope.fieldString);
                        $scope.loadingFieldsStatus = true;
                        $scope.onSubmitData = onSubmitData;
                    },
                    function (error) {
                        console.log(error);
                    }
                );

                // Form Two: Submit Assessment Results
                function onSubmitData() {
                    $scope.createResult = {
                        result_container_slug: $scope.result_slug,
                        assessment_values: [],
                        attachments: $rootScope.model_files_for_app
                    };

                    $scope.success.results.assessment_config_ref.parameters_ref_list.forEach(function (param_element, index, param_array) {
                        if ($scope.model[param_element.key] === "") {
                            $scope.model[param_element.key] = null
                        }
                        $scope.createResult.assessment_values[index] = {
                            param_name: param_element.form_label,
                            assessment_param_slug: param_element.assessment_param_slug,
                            value_type: param_element.value_type,
                            value: $scope.model[param_element.key]
                        }
                        if (param_element.value_type === "DAT" && $scope.model[param_element.key] !== null) {
                            $scope.createResult.assessment_values[index].value = formatDateForSaving($scope.model[param_element.key])
                        }
                    });
                    ResultFactory.update(
                        $scope.result_slug,
                        $scope.createResult,
                        function (success) {
                            $khMessage.show($filter('translate')('updated_successfully'), 5000);
                                $timeout(
                                    function () {
                                        closeDialog();
                                    }, 3000
                                );
                        }, function (error) {
                            $khMessage.show($filter('translate')('failed_to_create'), 5000);
                            $timeout(
                                function () {
                                    $location.path('/')
                                }, 3000
                            );
                        }
                    );
                };

                function createResultsDict(results_list) {
                    var result_dict = {};
                    results_list.forEach(function (result, i, a) {
                        result_dict[result.param_ref.$oid] = extractValue(result);
                    });
                    return result_dict
                }

                function extractValue(result) {
                    var value = null;
                    if (result.hasOwnProperty('num_value')) {
                        value = result.num_value;
                    } else if (result.hasOwnProperty('str_value')) {
                        value = result.str_value;
                    } else if (result.hasOwnProperty('date_value')) {
                        value = result.date_value.$date;
                    } else if (result.hasOwnProperty('time_value')) {
                        value = result.time_value;
                    } else {
                        value = "";
                    }
                    return value
                }

                function checkValue(parameter) {
                    var value = null;
                    if ($scope.results.hasOwnProperty(parameter._id.$oid)) {
                        if (parameter.value_type !== 'DAT') {
                            value = $scope.results[parameter._id.$oid]
                        } else if (parameter.value_type === 'DAT') {
                            value = moment(new Date($scope.results[parameter._id.$oid])).format('DD MMMM YYYY')
                        }
                    } else {
                        value = "";
                    }
                    return value;
                }

                function formatDateForSaving(dateString) {
                    return moment(dateString, 'DD MMMM YYYY').format('YYYY-MM-DD');
                }

                function getAttachments() {
                    if ($scope.success.results.attachments) {
                        fileUploads.displayExistingFiles($scope.success.results.attachments, $scope.fileuploadsconfig["db_type"]);
                    }
                };

                function closeDialog() {
                    $mdDialog.hide();
                }
            }
        ]
    );

})();