(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .controller("IrrigationSchedulingHistoricalDataController", IrrigationSchedulingHistoricalDataController);

    IrrigationSchedulingHistoricalDataController.$inject = ["$scope", "NgTableParams", "userSettingDateFormat", "IrrigationSchedulingFactory", "field_plot_crop_slug", "$mdDialog"];

    function IrrigationSchedulingHistoricalDataController($scope, NgTableParams, userSettingDateFormat, IrrigationSchedulingFactory, field_plot_crop_slug, $mdDialog) {

        IrrigationSchedulingFactory.get({field_plot_crop_slug: field_plot_crop_slug}, function (data) {
            loadTableData(data);
        });

        $scope.parseDate = function (jsonDate) {
            if (moment(jsonDate).isValid()) {
                return moment(jsonDate).format("DD MMM YYYY");
            }
            else {
                return jsonDate;
            }

        };

        function loadTableData(data) {
            $scope.tableParams = new NgTableParams({}, {dataset: data});
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }

})(angular);