angular.module('labApp').component('labNavbarAddButton', {
    templateUrl: '/static/assets/khapps/lab/secondary_navbar/lab-navbar-add-button.component.html',

    controller: function($mdDialog, LabPermissionsService, FarmFiltersService, $filter, $khMessage, $location) {
        var self = this;
        LabPermissionsService.promise.then(function() {
            self.show = LabPermissionsService.showAddEdit;
        });
        self.addNewSample = function () {
            if (FarmFiltersService.getGrowingSeason() === "All Seasons") {
                $khMessage.show($filter('translate')('message_select_a_season'), 5000);
            } else {
                $mdDialog.show({
                    controller: "SampleAddController",
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/lab/samples_and_results/sample_add_edit/SampleAddEditForm.html',
                    parent: angular.element(document.body),
                    fullscreen: true,
                    clickOutsideToClose: false
                }).then(function (data) {
                    if (data && data.sample_slug) {
                        $location.path('/samples/' + data.sample_slug)
                    }
                });
            }
        };
    }
});

