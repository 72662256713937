angular.module("NiabCufApp").run(["$http", "$rootScope", "PlatformTerminologyFactory", function ($http, $rootScope, PlatformTerminologyFactory) {
    $http.defaults.xsrfCookieName = "csrftoken";
    $http.defaults.xsrfHeaderName = "X-CSRFToken";

    $rootScope.in_app_links_template = '/static/assets/khapps/niabcuf/partials/includes/niabcuf_navbar_links.html';
    $rootScope.view_as_template = "/static/assets/khapps/niabcuf/partials/includes/niabcuf_navbar_views.html";
    $rootScope.select_filters_template = "/static/assets/khapps/niabcuf/partials/includes/niabcuf_navbar_filters.html";

    $rootScope.niabcuf_base_url = "/dashboard/niabcuf/#";

    PlatformTerminologyFactory.get({app_name: 'general'}, function (result) {
        $rootScope.platformTerminology = {};
        $rootScope.platformTerminology.general = result.General;
    });
}]);