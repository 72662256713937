angular.module("MyReportsFactoriesModule").factory('MyReportsListingFactory', ['$resource', function ($resource) {
    return $resource('/api/v2.0/myreports/reports/:slug/', {slug:'@slug'}, {
        query: {
            isArray: false
        },
        get: {
            method: 'GET'
        },
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        }
    });
}]);