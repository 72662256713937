angular.module("labApp").controller("LabSecondaryNavController", function ($scope, $mdDialog, $rootScope, $filter, $khMessage, $location) {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////   Filters     ////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    $scope.addNewSample = function () {
        if ($rootScope.selected_growing_season.name === "All Seasons") {
            $khMessage.show($filter('translate')('message_select_a_season'), 5000);
        } else {
            $mdDialog.show({
                controller: "SampleAddController",
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/lab/samples_and_results/sample_add_edit/SampleAddEditForm.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {},
                clickOutsideToClose: false
            }).then(function (data) {
                if (data && data.sample_slug) {
                    $location.path('/samples/' + data.sample_slug)
                }
            });
        }
    };

});

