angular.module("labApp").controller('SampleAddController',
    function ($scope,
              $rootScope,
              $location,
              $timeout,
              FarmResource,
              FcSampleFactory,
              FCSampleIdFactory,
              FCSampleFromIdFactory,
              StorageSampleFactory,
              StSampleIdFactory,
              StSampleFromIdFactory,
              StoreLotFactory,
              FieldStoreSamplesService,
              FieldStylingHelpersFactory,
              SampleNewForm,
              PlotResource,
              $mdDialog,
              $khMessage,
              $filter) {

    $scope.show_secondary_nav_filters = true;
    $scope.onSubmit = onSubmit;
    $scope.model = {sample_date: new Date()};
    $scope.container = {};
    $scope.options = {};
    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    if ($rootScope.platformTerminology.hasOwnProperty('labAnalytics')) {
        $scope.fcsFields = SampleNewForm.newSampleForm($scope.model)
    } else {
        $rootScope.$on("platformTerminology", function () {
            $scope.fcsFields = SampleNewForm.newSampleForm($scope.model)
        })
    }

    function onSubmit() {
        $scope.saving = true;

        if ($scope.model['sample_type'] === 'field') {
            const plot_id = $scope.model.plot_id;

            // we need the slug
            PlotResource.get({ id: plot_id }).$promise.then( plot => {
                $scope.model.field_plot_crop = plot.slug;
                FarmResource.get({id: plot.farm_id}).$promise.then(farm => {
                    $scope.model.farm_slug = farm.slug;
                    if (!$scope.model.hasOwnProperty('sample_location_ref')) {
                        if ($scope.model.hasOwnProperty('new_sample_location_id')) {
                            save_methods.saveFCSampleIdAndSample($scope.model);
                        } else {
                            if (!$scope.model.hasOwnProperty('new_store_lot_store')) {
                                save_methods.saveFCSampleFromScratch($scope.model);
                            }
                            if ($scope.model.hasOwnProperty('new_store_lot_store')) {
                                save_methods.saveStoreLotAndStSample($scope.model);
                            }
                        }
                    } else if ($scope.model.hasOwnProperty('sample_location_ref')) {
                        save_methods.saveFCSampleFromId($scope.model);
                    }

                })

            })

        } else if ($scope.model['sample_type'] === 'storage') {

            if (!$scope.model.hasOwnProperty('sample_location_ref')) {
                if ($scope.model.hasOwnProperty('new_sample_location_id')) {
                    save_methods.saveStSampleIdAndSample($scope.model);
                } else {
                    save_methods.saveStSampleFromScratch($scope.model);
                }
            } else if ($scope.model.hasOwnProperty('sample_location_ref')) {
                save_methods.saveStSampleFromId($scope.model)
            }

        }
    }


    function sampleSaved(result) {
        $khMessage.show($filter('translate')('created_successfully'), 5000);
        $scope.saving = false;
        $mdDialog.hide({sample_slug: result.data.sample_slug});
        FieldStoreSamplesService.reload();
    }

    function sampleFailed() {
        $khMessage.show($filter('translate')('failed_to_create'), 5000);
    }

    var save_methods = {

        saveFCSampleIdAndSample: function (model) {
            var sampleId = CreateFCSampleIdData(model);
            FCSampleIdFactory.save(sampleId, function (new_sample_id) {
                $khMessage.show($filter('translate')('created_successfully'), 5000);
                save_methods.saveFCSampleFromNewId(model, new_sample_id);
            }, function (error) {
                $khMessage.show($filter('translate')('failed_to_create'), 5000);
            });
        },

        saveFCSampleFromNewId: function (model, successResponse) {
            var result = FCSampleFromNewIdentifier(model, successResponse);
            FCSampleFromIdFactory.save(result, sampleSaved, sampleFailed);
        },

        saveFCSampleFromId: function (model) {
            var result = FCSampleFromIdentifier(model);
            FCSampleFromIdFactory.save(result, sampleSaved, sampleFailed);
        },

        saveFCSampleFromScratch: function (model) {
            var result = FCSampleFromScratch(model);
            FcSampleFactory.save(result, sampleSaved, sampleFailed);
        },

        saveStSampleIdAndSample: function (model) {
            var sampleId = createStSampleIdData(model);
            StSampleIdFactory.save(sampleId, function (successResponse) {
                $khMessage.show($filter('translate')('created_successfully'), 5000);
                save_methods.saveStSampleFromNewId(model, successResponse);
            }, function (error) {
                $khMessage.show($filter('translate')('failed_to_create'), 5000);
            });
        },

        saveStSampleFromNewId: function (model, successResponse) {
            var result = StSampleFromNewIdentifier(model, successResponse);
            StSampleFromIdFactory.save(result, sampleSaved, sampleFailed);
        },

        saveStSampleFromScratch: function (model) {
            var result = StSampleFromScratch(model);
            StorageSampleFactory.save(result, sampleSaved, sampleFailed);
        },

        saveStoreLotAndStSample: function (model) {
            StoreLotFactory.save(
                [{
                    field_plot_crop: model.field_plot_crop,
                    store: model.new_store_lot_store
                }],
                function (success) {
                    $khMessage.show($filter('translate')('created_successfully'), 5000);
                    var store_lot = success['store_lots'][0];
                    save_methods.saveStSampleFromNewStoreLot(model, store_lot)
                }, function (error) {
                    $khMessage.show($filter('translate')('failed_to_create'), 5000);
                }
            );
        },

        saveStSampleFromNewStoreLot: function (model, store_lot) {
            var result = StSampleFromNewStoreLot(model, store_lot);
            StorageSampleFactory.save(result, sampleSaved, sampleFailed);
        },

        saveStSampleFromId: function (model) {
            var result = StSampleFromIdentifier(model);
            StSampleFromIdFactory.save(result, sampleSaved, sampleFailed);
        }
    };


    // Field Sample Creation Functions
    function FCSampleFromScratch(model) {
        var createFCSample = {
            farm_slug: model['farm_slug'],
            field_plot_crop: model['field_plot_crop'],
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type']
        };
        if (model.hasOwnProperty('location_code')) {
            createFCSample['location_code'] = model.location_code;
        }
        if (model.hasOwnProperty('new_sample_location_id')) {
            createFCSample['location_code'] = model.new_sample_location_id;
        }
        if (model.hasOwnProperty('longitude')) {
            createFCSample.longitude = model.longitude;
        }
        if (model.hasOwnProperty('latitude')) {
            createFCSample.latitude = model.latitude;
        }
        if (model.hasOwnProperty('new_store_lot_store')) {
            createFCSample.store_lot_store = model.new_store_lot_store;
        }
        return createFCSample;
    }

    function FCSampleFromNewIdentifier(model, successResponse) {
        return {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type'],
            fpc_sample_id_slug: successResponse.data['sample_location_id_slug']
        };
    }

    function FCSampleFromIdentifier(model) {
        return {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type'],
            fpc_sample_id_slug: model['sample_location_ref']
        };
    }

    function CreateFCSampleIdData(model) {
        var createFCSampleID = {
            farm_slug: model['farm_slug'],
            field_plot_crop_slug: model['field_plot_crop'],
            fpc_sample_id_name: model['new_sample_location_id'],
        };
        if (model.hasOwnProperty('longitude')) {
            createFCSampleID.longitude = model.longitude;
        }
        if (model.hasOwnProperty('latitude')) {
            createFCSampleID.latitude = model.latitude;
        }
        if (model.hasOwnProperty('new_sample_location_assessment_category')) {
            createFCSampleID.sample_location_id_assessment = model.new_sample_location_assessment_category;
        }
        return createFCSampleID;
    }

    // Storage Sample Creation Functions
    function StSampleFromScratch(model) {
        var createStSample = {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type'],
            store_slug: model['store_ref'],
            store_lot: model['store_lot']
        };
        if (model.hasOwnProperty('location_code')) {
            createStSample['location_code'] = model.location_code;
        }
        if (model.hasOwnProperty('new_sample_location_id')) {
            createStSample['location_code'] = model.new_sample_location_id;
        }
        if (model.hasOwnProperty('longitude')) {
            createStSample.longitude = model.longitude;
        }
        if (model.hasOwnProperty('latitude')) {
            createStSample.latitude = model.latitude;
        }
        return createStSample;
    }

    function StSampleFromNewStoreLot(model, store_lot) {
        var createStSample = {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: 'storage',
            store_slug: model['new_store_lot_store'],
            store_lot: store_lot['storelot_slug']
        };
        if (model.hasOwnProperty('location_code')) {
            createStSample['location_code'] = model.location_code;
        }
        if (model.hasOwnProperty('longitude')) {
            createStSample.longitude = model.longitude;
        }
        if (model.hasOwnProperty('latitude')) {
            createStSample.latitude = model.latitude;
        }
        return createStSample;
    }

    function StSampleFromIdentifier(model) {
        return {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type'],
            storage_sample_id_slug: model['sample_location_ref']
        };
    }

    function createStSampleIdData(model) {
        var createStSampleID = {
            store_slug: model['store_ref'],
            store_lot: model['store_lot'],
            st_sample_id_name: model['new_sample_location_id'],
        };
        if (model.hasOwnProperty('longitude')) {
            createStSampleID.longitude = model.longitude;
        }
        if (model.hasOwnProperty('latitude')) {
            createStSampleID.latitude = model.latitude;
        }
        if (model.hasOwnProperty('new_sample_location_assessment_category')) {
            createStSampleID.sample_location_id_assessment = model.new_sample_location_assessment_category;
        }
        if (model.hasOwnProperty('associated_sample_location_ids')) {
            createStSampleID.associated_fpc_sample_location_list = model.associated_sample_location_ids;
        }
        return createStSampleID;
    }

    function StSampleFromNewIdentifier(model, successResponse) {
        return {
            sample_date: formatDateForSaving(model['sample_date']),
            sample_type: model['sample_type'],
            storage_sample_id_slug: successResponse.data['sample_location_id_slug']
        };
    }

    function formatDateForSaving(dateString) {
        return moment(dateString, 'DD MMMM YYYY').format('YYYY-MM-DD');
    }


});
