angular.module("MyReportsWidgetModule").controller("MyReportsWidgetDialogController", ["$scope", "$rootScope", "$mdDialog", "MyReportsListingFactory", "$khMessage", "UsersInTeamFactory", "TeamUserStatisticsFactory",
    function ($scope, $rootScope, $mdDialog, MyReportsListingFactory, $khMessage, UsersInTeamFactory, TeamUserStatisticsFactory) {
        $scope.selectedUsersAndTeams = [];
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.newReport = {
            "title": '',
            "url": 'http://127.0.0.1:8000/dashboard/myreports/#/',
            "description": '',
            "teams": [],
            "users": []
        };


        $scope.get_autocomplete_products = function (starts_with) {
            return TeamUserStatisticsFactory.query({"filter": {team: starts_with}}).$promise.then(function (data) {
                return data.results.map(function (team) {
                    return {
                        value: team.team_slug,
                        display: team.team_name
                    };
                });
            });
        };

        $scope.emptyFields = function (itemType) {
            if (itemType == 'team') {
                $scope.selectedTeam = '';
            } else {
                $scope.selectedUser = '';
            }
        };

        $scope.addToSelectedItem = function (item, itemType) {
            for (var i = 0; i < $scope.selectedUsersAndTeams.length; i++) {
                if ($scope.selectedUsersAndTeams[i].value == item.value) {
                    $khMessage.show(itemType.charAt(0).toUpperCase() + itemType.slice(1) + " already selected!");
                    return false;
                }
            }
            $scope.selectedUsersAndTeams.push({
                "itemType": itemType,
                "item": item.display,
                "value": item.value
            });
            $scope.emptyFields(itemType);
        };

        $scope.removeFromSelectedItems = function (item) {
            for (var i = 0; i < $scope.selectedUsersAndTeams.length; i++) {
                if (item.value == $scope.selectedUsersAndTeams[i].value) {
                    $scope.selectedUsersAndTeams.splice(i, 1);
                }
            }
        };

        $scope.addnewReportLink = function (report) {
            $scope.newReport["users"] = [1, 384];
            $scope.newReport["teams"] = [4];
            $scope.newReport["sub_app"] = 64;
            MyReportsListingFactory.save($scope.newReport, function (result) {
                $khMessage.show("New report created!");
            }, function (err) {
                $khMessage.show(err);
            });
        };
    }
]);