angular.module("MyReportsFactoriesModule").factory('MyReportCategoryFactory', ['$resource', function ($resource) {
    return $resource('/api/v2.0/myreports/categories/', {}, {
        query: {
            isArray: false
        },
        get: {
            method: 'GET'
        }
    });
}]);
