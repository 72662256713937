angular.module("labApp").controller('LabDistributionListController',
    function ($scope, $rootScope,
              SelectedTeamService,
              TeamUsersFactory, AssessmentFactory, ModelAttributeFilterAdapterFactory, $khMessage,
              $filter) {

        $rootScope.page_id = "labanalytics";
        $rootScope.sub_app_page_id = "labanalytics_distribution_lists";
        $rootScope.currentNavItem = 'distribution_list';

        $scope.LabDistributionList = [
            {
                card_title: $filter('translate')('notification_lists_title'),
                kh_card_content_template: '/static/assets/khapps/lab/notifications/lab_distribution_list_details.html',
                card_flex: 100
            },
            {
                card_title: $filter('translate')('title_list_for_immediate_notification'),
                kh_card_content_template: '/static/assets/khapps/lab/notifications/lab_immediate_notification_list.html',
                card_flex: 50
            },
            {
                card_title: $filter('translate')('title_list_for_round_up_notification'),
                kh_card_content_template: '/static/assets/khapps/lab/notifications/lab_round_up_notification_list.html',
                card_flex: 50
            }

        ];
        $scope.harvest_assessment = {};
        populate_assessment_dropdown();
        populate_user_multiselect();

        let sub = SelectedTeamService.getTeamSlug$().subscribe(team_changed);

        $scope.$on('$destroy', function () {
            sub.dispose();
        });

        function team_changed(selected_team) {
            if (selected_team) {
                 reset_user_mutliselect_with_data([]);
                 $scope.harvest_assessment = {};
                 populate_assessment_dropdown();
                 populate_user_multiselect();
            }
        }

        function populate_user_multiselect() {
            var data = TeamUsersFactory.query({'team_slug':  SelectedTeamService.getSelectedSlug()});
            reset_user_mutliselect_with_data(data);
        }

        function distribution_list_multiselect(data) {
            return [
                {
                    className: "flex-100",
                    key: "users",
                    type: 'searchable_select',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        multiple: true,
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        label: "Users",
                        valueProp: 'value',
                        labelProp: 'name',
                        placeholder: $filter('translate')('placeholder_select_users'),
                        options: data
                    },
                    validators: {
                        implementers: {
                            expression: function (viewValue, modelValue) {
                                if (modelValue) {
                                    return modelValue.length > 0;
                                }
                                else {
                                    return false;
                                }
                            }
                        }
                    }
                }

            ];
        }

        function reset_user_mutliselect_with_data(data) {

            $scope.immediate_distribution_list_multiselect = distribution_list_multiselect(data);
            $scope.roundup_distribution_list_multiselect = distribution_list_multiselect(data);
        }

        function distribution_list_dropdown(data) {
            return [{
                type: 'searchable_select',
                key: "harvest_assessments",
                templateOptions: {
                    optionsAttr: 'bs-options',
                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                    valueProp: 'assessment_config_slug',
                    labelProp: 'name',
                    placeholder: $filter('translate')('notification_assessment_placeholder'),
                    label: "Harvest Assessments",
                    options: data
                }
            }
            ];
        }

        function populate_assessment_dropdown() {
            AssessmentFactory.query(
                {"shared": "True"},
                function (data) {
                    var assessment_list = _.filter(data, (x)=> { return x.assessment_classification === 'Harvest'});
                    $scope.distribution_list_dropdown = distribution_list_dropdown(assessment_list);
                },
                function (error) {
                    console.log("Error: ", error);
                }
            );
        }

        function check_if_all_teams() {
            if (SelectedTeamService.allTeamsSelected()) {
                $khMessage.show($filter('translate')('all_teams_selected_message'), 5000);
                return true;
            }
            return false;
        }

        $scope.$watch("harvest_assessment.harvest_assessments", function (newvalue, oldvalue) {
            reset_model('immediate_distribution_list');
            add_exists('immediate_distribution_list');
            reset_model('roundup_distibution_list');
            add_exists('roundup_distibution_list');
            if (newvalue) {
                if (!check_if_all_teams()) {
                    let retrieve_dict = construct_parameters(newvalue, "Immediate");
                    get_model_attribute_filter_adapter_factory(retrieve_dict, 'immediate_distribution_list');
                    //for roundup
                    retrieve_dict = construct_parameters(newvalue, "Round-up");
                    get_model_attribute_filter_adapter_factory(retrieve_dict, 'roundup_distibution_list');
                }

            }
        });


        $scope.saveDistribution = function (model_name, label) {
            if (!check_if_all_teams()) {
                construct_post_data(model_name, label);
                save_model_adpter(model_name);
            }

        };
        $scope.updateDistribution = function (model_name) {
            if (!check_if_all_teams()) {
                update_model_attribute_filter_adapter(model_name);
            }
        };

        function add_exists(model_name) {
            $scope[model_name].exists = false;
        }

        function construct_parameters(newvalue, label) {
            return {
                "team_slug": SelectedTeamService.getSelectedSlug(),
                "modelattributefilteradapter_slug": "modelattributefilteradapter_slug",
                "key": "assessment_config_slug",
                "value": newvalue,
                "data_store_name": "AssessmentConfigurator",
                "label": label
            };
        }

        function get_model_attribute_filter_adapter_factory(retrieve_dict, model_name) {
            ModelAttributeFilterAdapterFactory.get(retrieve_dict, function (data) {
                $scope[model_name].modelattributefilteradapter_slug = data.modelattributefilteradapter_slug;
                $scope[model_name].users = _.pluck(data.users, 'value');
                $scope[model_name].exists = true;
            }, function () {
                $scope[model_name].exists = false;
            });
        }

        function reset_model(model_name) {
            $scope[model_name] = {};
        }

        function construct_post_data(model_name, label) {
            $scope[model_name]['key_value'] = [];
            var key_value_dict = {
                "value": $scope.harvest_assessment['harvest_assessments'],
                "key": "assessment_config_slug"
            };
            $scope[model_name]['team_slug'] = SelectedTeamService.getSelectedSlug();
            $scope[model_name]['key_value'].push(key_value_dict);
            $scope[model_name]['channel'] = ['Email'];
            $scope[model_name]['distribution_list_name'] = 'AssessmentConfigurator-' + label + '-' +
                SelectedTeamService.selected.slug + '-' + $scope.harvest_assessment['harvest_assessments'];
            $scope[model_name]['data_store_name'] = 'AssessmentConfigurator';
            $scope[model_name]['label'] = label;
        }

        function save_model_adpter(model_name) {
            ModelAttributeFilterAdapterFactory.save($scope[model_name], function (data) {
                $khMessage.show($filter('translate')('created_successfully'), 5000);
                $scope[model_name].exists = true;
                $scope[model_name].modelattributefilteradapter_slug = data.modelattributefilteradapter_slug;
            }, function () {
                $khMessage.show($filter('translate')('failed_to_update'), 5000);
            });
        }

        function update_model_attribute_filter_adapter(model_name) {
            ModelAttributeFilterAdapterFactory.update(
                $scope[model_name],
                function () {
                    $khMessage.show($filter('translate')('updated_successfully'), 5000);
                }, function () {
                    $khMessage.show($filter('translate')('failed_to_update'), 5000);
                }
            );
        }


});
