angular.module("MyReportsApp").config(["$routeProvider", "$resourceProvider", "$translateProvider",
    function ($routeProvider, $resourceProvider, $translateProvider) {
        $translateProvider.translations('en', {
            'clear_filters': 'Clear filters',
			applied_filters: 'Applied filters',
            filter_by_applications: 'Filter by applications',
            filter_by_categories: 'Filter by categories',
            change_alert_status: 'Change alert status',
            alert_status: 'Alert status',
            generate_new_report: 'Generate new report',
            title: 'Title',
            url: 'URL',
            selected_users_and_teams: 'Selected users and teams',
            team_slug: 'Team slug',
            user_team: 'User team'
        });
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'MyReports',
                controller: "MyReportsListingController",
                templateUrl: "/static/assets/khapps/my_reports/my_reports_listing/MyReportsListing.html"
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);