(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .factory("IrrigationSchedulingFactory", IrrigationSchedulingFactory);

    function IrrigationSchedulingFactory($resource) {

        return $resource("/api/v1.0/external/niabcuf/irrigation-schedule/", {}, {

            get: {
                params: {field_plot_crop_slug: "@field_plot_crop_slug"},
                url: "/api/v1.0/external/niabcuf/irrigation-schedule/:field_plot_crop_slug/",
                method: "GET",
                isArray: true

            }
        });

    }

})(angular);