angular.module('labApp').component('sampleLocationList', {
    templateUrl: '/static/assets/khapps/lab/sample_locations/sample-location-list.component.html',
    controller: function ($rootScope,
                          ErrorLoggingService,
                          FCSampleIdFactory,
                          StSampleIdFactory,
                          SelectedTeamService,
                          FarmFiltersService,
                          NgTableParams,
                          $q) {

        var self = this;
        $rootScope.page_id = "labanalytics";
        $rootScope.sub_app_page_id = "labanalytics_sample_locations";
        $rootScope.currentNavItem = 'sample_locations';

        self.loaded = false;
        self.pt = $rootScope.platformTerminology;
        self.search = null;

        self.data = {
            field: [],
            store: []
        };

        self.tableParams = {
        };

        self.model = {};

        var subs = [
            FarmFiltersService.get$().subscribe(refresh),
            SelectedTeamService.getTeamSlug$().subscribe(refresh)
        ];


        function createTableData() {
            var search = (self.search)?self.search.toLowerCase():null;
            function commonFilter(item) {
                return !self.search || item.sample_location_id.name.toLowerCase().indexOf(search)>=0
            }
            self.tableParams.field = new NgTableParams({}, {dataset: _.filter(self.data.field, commonFilter)});
            self.tableParams.storage = new NgTableParams({}, {dataset: _.filter(self.data.storage, commonFilter)});
        }

        function refresh(filter) {
            if (filter && FarmFiltersService.getGrowingSeason()) {
                var params = {
                    team: SelectedTeamService.getSelectedSlug(),
                    growing_season: FarmFiltersService.getGrowingSeason()
                };
                self.loading = true;

                $q.all([FCSampleIdFactory.query(params).$promise,
                        StSampleIdFactory.query(params).$promise]).then(function(results) {
                   self.loading = false;
                   self.data.field=  results[0];
                   self.data.storage = results[1];
                   createTableData();
                   self.loaded = true;
                });
            }
        }

        self.filterChanged = function(search) {
            self.search = search;
            createTableData();
        };

        self.$onDestroy = function () {
            _.each(subs, function(sub) {
                sub.dispose();
            });
        };

    }
});



