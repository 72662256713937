angular.module("labApp").controller('ResultsListController', function ($scope,
                                                                       $rootScope,
                                                                       AssessmentFactory,
                                                                       FarmFiltersService,
                                                                       AggregatedResultsFactory,
                                                                       NgTableParams) {

    $rootScope.page_id = "labanalytics";
    $rootScope.sub_app_page_id = "labanalytics_samplesresults";
    $rootScope.currentNavItem = 'results';

    let currentFarmFieldFilter = null;

    $scope.updateResults = updateResults;
    $scope.fieldLoading = false;
    $scope.storageLoading = false;
    $scope.fieldResultsData = [];
    $scope.storageResultsData = [];
    $scope.resultCards = [{
        kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/assessment_result_list/result_list_cards.html',
        card_flex: 100
    }];

    $scope.assessmentModel = {};
    $scope.options = {};
    $scope.assessmentDropdown = [
        {
            key: 'assessment_slug',
            type: 'searchable_select',
            templateOptions: {
                label: 'Please select an assessment',
                required: true,
                focus: true,
                options: [],
                valueProp: 'assessment_config_slug',
                labelProp: 'name',
                onChange: function () {
                    updateResults()
                }
            },
            controller: function ($scope, AssessmentFactory) {
                AssessmentFactory.query({"shared": "True"}, function (result) {
                    $scope.assessment_count = result.length;
                    $scope.to.options = result;
                    $scope.model.assessment_slug = result[0].assessment_config_slug;
                })
            }
        }
    ];

    let sub = FarmFiltersService.get$().subscribe((filter) => {
        if (filter) {
            currentFarmFieldFilter = filter;
            updateResults()
        }
    });

    $scope.$on('$destroy', function () {
        sub.unsubscribe();
    });

    function updateResults() {
        if (currentFarmFieldFilter && $scope.assessmentModel && $scope.assessmentModel.assessment_slug) {
            $scope.fieldLoading = $scope.storageLoading = true;
            AggregatedResultsFactory.get({
                assessment_slug: $scope.assessmentModel.assessment_slug,
                growing_season: currentFarmFieldFilter.growing_season,
                team: currentFarmFieldFilter.team_slug,
                'type': 'field'
            }, function (success_data) {
                $scope.fieldResults = success_data.results;
                $scope.fieldParams = success_data.parameters;
                $scope.fieldFilters = success_data.filters;
                $scope.fieldSpecs = success_data.specifications;
                loadTableData('fieldResultsTable', $scope.fieldResults, NgTableParams);
                $scope.fieldLoading = false;
            }, function (error_data) {
                $scope.fieldLoading = false;
            })
            AggregatedResultsFactory.get({
                assessment_slug: $scope.assessmentModel.assessment_slug,
                growing_season: currentFarmFieldFilter.growing_season,
                team: currentFarmFieldFilter.team_slug,
                'type': 'storage'
            }, function (success_data) {
                $scope.storageResults = success_data.results;
                $scope.storageParams = success_data.parameters;
                $scope.storageFilters = success_data.filters;
                $scope.storageSpecs = success_data.specifications;
                loadTableData('storageResultsTable', $scope.storageResults, NgTableParams);
                $scope.storageLoading = false;
            }, function (error_data) {
                $scope.storageLoading = false;
            })
        }
    }

    function loadTableData(scopeObject, data, NgTableParams) {
        $scope[scopeObject] = new NgTableParams({}, {dataset: data});
    }

    $scope.getFilterColour = getFilterColour;

    function getFilterColour(threshold_category) {
        if (threshold_category === "Fail") {
            return "background-color:rgba(183,28,28,0.4);";
        }
        if (threshold_category === "Caution") {
            return "background-color:rgba(255,111,0,0.4);";
        }
        if (threshold_category === "Pass") {
            return "background-color:rgba(27,94,32,0.4);";
        }
    }

    $scope.getSpecColour = getSpecColour;

    function getSpecColour(threshold_category) {
        if (threshold_category === "Fail") {
            return "background-color:rgba(183,28,28,1.0);";
        }
        if (threshold_category === "Pass") {
            return "background-color:rgba(27,94,32,1.0);";
        }
    }


});

