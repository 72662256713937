angular.module('labApp')
.controller('AssessmentResultAddController', function ($rootScope,
                                                       $scope,
                                                       $location,
                                                       $routeParams,
                                                       $timeout,
                                                       GenericSampleService,
                                                       AssessmentFactory,
                                                       ResultFactory,
                                                       NewResultsHelpersFactory,
                                                       ErrorLoggingService,
                                                       $khMessage,
                                                       $filter,
                                                       sample_slug,
                                                       $mdDialog) {

    $scope.edit = false;
    $scope.dialogTitle = $filter('translate')('new_results');
    $scope.loadingFieldsStatus = false;
    $scope.sample_slug = sample_slug;
    $scope.show_secondary_nav_filters = true;
    $scope.closeDialog = closeDialog;
    getSampleDetail($scope);

    $scope.fileuploadsconfig = {
        show_files: true,
        app_tag: 'LabAnalytics',
        max_files: 6,
        db_type: 'mongo',
        display_type: 'detailed',
        allow_duplicates: true
    };

    // Form One: Sample Summary & Selection of Assessment to collect data for
    $scope.onSubmitMeta = onSubmitMeta;
    $scope.metaModel = {};
    $scope.metaOptions = {};
    $scope.metaFields = [
        {
            key: 'assessment_config_slug',
            type: 'searchable_select',
            templateOptions: {
                label: 'Assessment',
                required: true,
                focus: true,
                options: [],
                valueProp: 'assessment_config_slug',
                labelProp: 'name',
                onChange: function () {
                    onSubmitMeta($scope, $rootScope)
                }
            },
            controller: function ($scope, AssessmentFactory) {
                AssessmentFactory.query({"shared": "True"}, function (result) {
                    $scope.assessment_count = result.length;
                    $scope.to.options = result;
                })
            }
        }
    ];

    function onSubmitMeta(scope, rootScope) {
        AssessmentFactory.get(
            scope.metaModel['assessment_config_slug'],
            {},
            function (result) {
                scope.model = {};
                scope.options = {};
                scope.metaResult = result;

                var params = _.sortBy(result.parameters_ref_list, function (param) {
                    return param.form_position;
                });
                scope.fieldString = NewResultsHelpersFactory.createForm(params, 'new');
                scope.fields = eval(scope.fieldString);
                scope.onSubmitData = onSubmitData;
                scope.loadingFieldsStatus = true;
            }, function (error) {
                console.log("Error: ", error);
            }
        )
    }

    // Form Two: Submit Assessment Results
    function onSubmitData(sample_slug, metaModel, metaResult, model, files_list) {
        $scope.saving = true;
        var createResult = {
            sample_slug: sample_slug,
            assessment_config_slug: metaModel.assessment_config_slug,
            assessment_values: [],
            attachments: $rootScope.model_files_for_app
        };
        createResult = NewResultsHelpersFactory.createSubmission(metaResult.parameters_ref_list, model, createResult);
        ResultFactory.save(
            createResult,
            function (success) {
                $khMessage.show($filter('translate')('created_successfully'), 5000);
                $timeout(
                    function () {
                        closeDialog();
                    }, 3000
                );
            }, function (error) {
                $khMessage.show($filter('translate')('failed_to_create'), 5000);
            }
        );
    }

    function getSampleDetail(scope) {
        GenericSampleService.get(scope.sample_slug).then(function(success) {
                scope.sample_detail = {};
                scope.sample_detail.sample_type = success.sample_type;
                scope.storageBoolean = checkStorage(scope.sample_detail.sample_type);
                scope.sample_detail.store_name = success.store_name;
                scope.sample_detail.sample_date = success.sample_date;
                scope.sample_detail.farm_name = success.farm_name;
                scope.sample_detail.field_plot_crop = success.field_plot_crop;
                scope.sample_detail.location_code = success.location_code;
            }, ErrorLoggingService.genericAPIError);
    }

    function checkStorage(input) {
        return input === 'storage';
    }

    function closeDialog() {
        $mdDialog.hide();
        $scope.saving = false;
    }

});