(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .factory("IrrigationSummaryGroundCoverFactory", IrrigationSummaryGroundCoverFactory);

    function IrrigationSummaryGroundCoverFactory($resource) {
        var url = "/api/v1.0/external/niabcuf/crop-groundcover/";
        var queryParamDict = {
            season: "@selected_season",
            selected_farm: "@selected_farm",
            selected_team: "@selected_team",
            start_date: "@start_date",
            end_date: "@end_date"
        };
        return $resource(url, {},
            {
                query: {
                    params: queryParamDict,
                    url: url
                }
            }
        );

    }

})(angular);