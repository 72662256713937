(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .factory("IrrigationDataSubmissionFactory", IrrigationDataSubmissionFactory);

    function IrrigationDataSubmissionFactory($resource) {
        var url = " /api/v1.0/external/niabcuf/irrigation-data-submission/";
        return $resource(url, {},
            {
                save: {
                    method: "POST"
                }
            }
        );
    }

})(angular);