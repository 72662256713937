(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .controller("IrrigationDataSubmissionController", IrrigationDataSubmissionController);

    IrrigationDataSubmissionController.$inject = ["$scope", "$rootScope", "$mdDialog", "IrrigationDataSubmissionFactory", "TeamsOfUserFactory", "$khMessage"];


    function IrrigationDataSubmissionController($scope, $rootScope, $mdDialog, IrrigationDataSubmissionFactory, TeamsOfUserFactory, $khMessage) {

        TeamsOfUserFactory.query(function (result) {

            result.unshift({team_name: "All Teams", team_slug: "All Teams"});
            $scope.teams = result;
            $scope.teams.shift();


            $scope.check_if_selected = function () {
                for (var i = 0; i < $scope.teams.length; i++) {
                    if ($scope.teams[i].hasOwnProperty("selected_team_model") && $scope.teams[i].selected_team_model) {
                        return false;
                    }
                }
                return true;
            };

            $scope.submitIrrigationData = function () {
                var payload = [];
                angular.forEach($scope.teams, function (value, key) {
                    if (value.hasOwnProperty("selected_team_model") && value.selected_team_model) {
                        var payload_dict = {};
                        payload_dict["team_id"] = value.team_slug;
                        payload_dict["season"] = $rootScope.selected_growing_season.value;
                        payload.push(payload_dict);

                    }
                });
                IrrigationDataSubmissionFactory.save(payload, function (data) {
                    $khMessage.show("Your data submission request has been accepted and the email will be sent shortly", 5000);
                    $mdDialog.hide();
                });

            };
        });

        $scope.cancel = function () {
            $mdDialog.cancel();
        };


    }

})(angular);
