angular.module("MyReportsListingModule").controller("MyReportsListingController", ["$scope", "$rootScope", "MyReportsListingFactory", "SideNavFactory", "$khMessage", "$mdDialog",
    "MyReportCategoryFactory",
    function ($scope, $rootScope, MyReportsListingFactory, SideNavFactory, $khMessage, $mdDialog, MyReportCategoryFactory) {

        $rootScope.page_id = "my_reports";
        $scope.reportSearchItems = [];
        $scope.pageSize = 20;
        $scope.currentPage = 1;
        $scope.total_pages = [];
        $scope.alertsTypes = {
            CRITICAL: "Critical",
            INFO: "Info",
            WARNING: "Warning"
        };
        $scope.new_list = {};

        $scope.withoutSearchResult = function () {
            MyReportsListingFactory.get(function (result) {
                $scope.result = result;
                buildPages($scope.result.total_pages);
            });
        };

        $scope.withoutSearchResult();

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.clearFilter = function () {
            delete $scope.filter;
            $scope.search_query = '';
            $scope.withoutSearchResult();
        };
        $scope.checkForClearing = function (searchQuery) {
            if (searchQuery.length == 0) {
                $scope.clearFilter();
            }
        };

        const generate_range = (start, count) => {
            // generates the page numbers required to populate the drop down
            const length = count - start + 1;
            return Array.from({ length }, (_, i) => start + i);
        };


        function buildPages(count) {

            $scope.total_pages = generate_range(1, count, 1);
            return $scope.total_pages;
        }

        $scope.pageChanged = function (pageNum) {
            // query for the page number
            MyReportsListingFactory.query({page: parseInt(pageNum)}, function (result) {
                $scope.result = result;
            });
            // maintain a current page variable
            $scope.currentPage = parseInt(pageNum);
        };

        MyReportCategoryFactory.query({lang:"en"}, function (result) {
            $scope.filter_names = [];
            for (var i = 0; i < result.data.length; i++) {
                $scope.filter_names.push({
                    title: result.data[i].title,
                    slug: result.data[i].slug
                });
            }
        });

        $scope.checkIfPageExists = function (page) {
            return $scope.total_pages.includes(parseInt(page));
        };

        $scope.searchArticles = function (search_query) {
            if (search_query.length > 0) {
                MyReportsListingFactory.query({search: search_query}, function (result) {
                    $scope.result = result;
                });
            }
        };

        $scope.searchForFilters = function (query) {
            MyReportsListingFactory.query({filters:query}, function (result) {
                $scope.result = result;
            });
        };

        $scope.pushToSearchItems = function (query) {
            $scope.filter = query;
            var search_query = {
                "categories": query.slug
            };
            $scope.searchForFilters(search_query);

        };

        $scope.resendNotification = function (ev, report) {
            var get_report = MyReportsListingFactory.get({slug: report.slug});
            var confirm = $mdDialog.confirm()
                .title('Would you like to resend notification?')
                .textContent("Notification'll go to all the user this report is shared with.")
                .ariaLabel('Notification status')
                .targetEvent(ev)
                .ok('Send')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                get_report.$update({send_notification: true}, function () {
                    report["notification_status"] = true;
                    $khMessage.show("Notification sent!", 2000);
                });
            }, function () {
            });
        };
        $scope.resetAlertStatus = function (ev, report) {
            $mdDialog.show({
                controller: "MyReportsListingController",
                templateUrl: '/static/assets/khapps/my_reports/my_reports_listing/ResetAlertStatusDialog.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true
            })
                .then(function (answer) {
                    $scope.status = 'You said the information was "' + answer + '".';
                }, function () {
                    $scope.status = 'You cancelled the dialog.';
                });
        };
        $scope.editReport = function (ev, report) {
            $mdDialog.show({
                controller: "MyReportsWidgetDialogController",
                template: '<my-reports-widget></my-reports-widget>',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false
            });
        };
    }
]);