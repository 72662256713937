angular.module("labApp").controller('ReportController', function ($scope,
                                                                  $rootScope,
                                                                  $khMessage,
                                                                  $filter,
                                                                  $window,
                                                                  FarmFiltersService,
                                                                  FilterParameters,
                                                                  LabReportSelectionResource,
                                                                  NgTableParams) {

    $rootScope.page_id = "labanalytics";
    $rootScope.sub_app_page_id = "labanalytics_reports";
    $rootScope.currentNavItem = null;

    $scope.GenerateFieldFryReport = GenerateFieldFryReport;
    $scope.GenerateStorageFryReport = GenerateStorageFryReport;
    $scope.LabReportingCards = [{
        card_title: 'Lab Reports',
        kh_card_content_template: getClientReportsView(tenant_id),
        card_flex: 100
    }];

    let lab_report_get = null;

    let sub = FarmFiltersService.get$().subscribe((filter) => {
        if (filter) {
            updateReports(filter);
        }
    });


    $scope.$on('$destroy', function () {
        sub.dispose();
    });

    function getClientReportsView(tenant_id) {
        var client_templates = {
            spearhead: '/static/assets/khapps/lab/reports/client_specific/spearhead.html'
        };
        if (Object.keys(client_templates).includes(tenant_id)) {
            return client_templates[tenant_id]
        } else {
            return '/static/assets/khapps/lab/reports/client_specific/spearhead.html'
        }
    }


    function updateReports(filter) {
        if (lab_report_get) {
            lab_report_get.$cancelRequest();
        }
        lab_report_get = LabReportSelectionResource.get({team_id: filter.team_id, season: filter.growing_season});

        lab_report_get.$promise.then((result)=>{
                $scope.field_fry = result.field.Fry;
                $scope.field_ysi = result.field.YSI;
                $scope.storage_fry = result.storage.Fry;
                $scope.storage_ysi = result.storage.YSI;
                loadTableData('fieldFrySamplesParams', $scope.field_fry);
                loadTableData('fieldYsiSamplesParams', $scope.field_ysi);
                loadTableData('storeFrySamplesParams', $scope.storage_fry);
                loadTableData('storeYsiSamplesParams', $scope.storage_ysi);
            }
        );
    }

    function generateReport(type, slug, sample_date_str) {
        var sample_date = new Date(sample_date_str);
        var year = sample_date.getFullYear();
        var month = sample_date.getMonth() + 1;
        var date_str = sample_date.getDate();
        var season = FarmFiltersService.getGrowingSeason().replace('/','-');
        $window.open(`/lab-reports/single/type/${type}/container/` +
            `${slug}/season/${season}/year/${year}/month/${month}/date/${date_str}/`, '_blank');

    }
    function GenerateFieldFryReport(farm_slug, sample_date_str) {
        generateReport('field', farm_slug, sample_date_str)
    }

    function GenerateStorageFryReport(store_slug, sample_date_str) {
        generateReport('storage', store_slug, sample_date_str);
    }

    function loadTableData(scopeObject, data) {
        $scope[scopeObject] = new NgTableParams({count: 10}, {dataset: data});
    }

    $scope.searchSampleDate = {
        sample_date: {
            id: 'text',
            placeholder: 'Filter by sample date'
        }
    };
    $scope.searchCropDetail = {
        fpc_name: {
            id: 'text',
            placeholder: 'Filter by crop details'
        }
    };
    $scope.searchFarmName = {
        farm_name: {
            id: 'text',
            placeholder: 'Filter by farm'
        }
    };
    $scope.searchStoreName = {
        store_name: {
            id: 'text',
            placeholder: 'Filter by store'
        }
    };
    $scope.searchStoreLot = {
        store_lot: {
            id: 'text',
            placeholder: 'Filter by store-lot'
        }
    };
    $scope.searchSampleLocation = {
        location_code: {
            id: 'text',
            placeholder: 'Filter by sample location reference'
        }
    };
});
