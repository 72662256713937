(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .controller("IrrigationSummaryController", IrrigationSummaryController);

    IrrigationSummaryController.$inject = ["$scope", "$rootScope", "IrrigationSummaryRainfallFactory", "IrrigationSummaryIrrigationFactory", "IrrigationSummaryGroundCoverFactory", "NgTableParams", "$mdDialog"];

    function IrrigationSummaryController($scope, $rootScope, IrrigationSummaryRainfallFactory, IrrigationSummaryIrrigationFactory, IrrigationSummaryGroundCoverFactory, NgTableParams, $mdDialog) {
        $rootScope.page_id = "potatodashboard";
        $rootScope.sub_app_page_id = "potatodashboard_niabcuf_irrigation";

        $rootScope.view_only_irrigation = true;
        $rootScope.currentNavItem = "summary";
        $scope.date_range = {};
        $scope.myDate = new Date();

        $scope.date_range.dateStart = new Date(
            $scope.myDate.getFullYear(),
            $scope.myDate.getMonth(),
            $scope.myDate.getDate() - 7
        );
        $scope.date_range.dateEnd = new Date();


        $scope.minDate = new Date(
            $scope.myDate.getFullYear(),
            $scope.myDate.getMonth(),
            $scope.myDate.getDate() - 30
        );

        $scope.maxDate = new Date();

        $scope.$on("filter:selections_updated", function (obj) {
            get_irrigation_summary_data();
            include_notifications_dialog();
        });


        $scope.apply_date_range_on_irrigation_data = function () {
            get_irrigation_summary_data();

        };

        $scope.validate_dates = function () {
            if (!$scope.date_range.dateStart || !$scope.date_range.dateEnd) {
                return true;

            }
            else if (moment($scope.date_range.dateStart) > moment($scope.date_range.dateEnd)) {
                return true;
            }
            else {
                return false;
            }
        };

        $scope.$on("$routeChangeSuccess", function (scope, next, previous) {
            if (previous) {
                get_irrigation_summary_data();
                include_notifications_dialog();
            }
        });

        function get_irrigation_summary_data() {
            get_and_populate_rainfall_data();
            get_and_populate_groundcover_data();
            get_and_populate_irrigation_data();
        }

        function include_notifications_dialog() {
            $scope.openSubmitDataDialog = function () {

                $mdDialog.show({
                    animation: true,
                    templateUrl: "/static/assets/khapps/niabcuf/irrigation/partials/irrigation_data_submission.html",
                    controller: "IrrigationDataSubmissionController"
                }).then(function (data) {
                });

            };
        }


        function get_and_populate_rainfall_data() {
            IrrigationSummaryRainfallFactory.query({
                "team": $rootScope.selected_team,
                "season": $rootScope.selected_growing_season.value,
                "selected_farm": $rootScope.selected_farm.value,
                "start_date": new Date(moment($scope.date_range.dateStart).add(12, "h").hour(12).format()),
                "end_date": $scope.date_range.dateEnd
            }, function (payload) {
                var rainfall_data = payload.data;
                $scope.irrigationSchedulingRainfallData = rainfall_data;
                $scope.RainfallTableParams = new NgTableParams({}, {dataset: rainfall_data});
                $scope.RainfallTableParams.reload();
                $scope.showEmptyMessageRainfall = (rainfall_data.length == 0);

                $scope.rainfallColumns = [];
                for (var key in rainfall_data[0]) {
                    if (rainfall_data[0].hasOwnProperty(key)) {
                        var dictionary = {
                            field: key,
                            show: true,
                            sortable: key
                        };
                        if (key == "crop") {
                            dictionary["thtitle"] = "Crop";
                            var filterDict = {};
                            filterDict[key] = {
                                id: "text",
                                placeholder: "Crop"
                            };
                            dictionary["tooltiptext"] = "Crop";
                            dictionary["filter"] = filterDict;
                            $scope.rainfallColumns.unshift(dictionary);
                        }
                        else {
                            dictionary["thtitle"] = key;
                            dictionary["tooltiptext"] = key;
                            $scope.rainfallColumns.push(dictionary);
                        }


                    }
                }


            });
        }

        function get_and_populate_groundcover_data() {
            IrrigationSummaryGroundCoverFactory.query({
                "team": $rootScope.selected_team,
                "season": $rootScope.selected_growing_season.value,
                "selected_farm": $rootScope.selected_farm.value,
                "start_date": new Date(moment($scope.date_range.dateStart).add(12, "h").hour(12).format()),
                "end_date": $scope.date_range.dateEnd
            }, function (payload) {
                var ground_cover_data = payload.data;
                $scope.GroundCoverTableParams = new NgTableParams({}, {dataset: ground_cover_data});
                $scope.showEmptyMessageGroundCover = (ground_cover_data.length == 0);

                $scope.groundCoverColumns = [];
                for (var key in ground_cover_data[0]) {
                    if (ground_cover_data[0].hasOwnProperty(key)) {
                        var dictionary = {
                            field: key,
                            show: true,
                            sortable: key
                        };
                        if (key == "crop") {
                            dictionary["thtitle"] = "Crop";
                            var filterDict = {};
                            filterDict[key] = {
                                id: "text",
                                placeholder: "Crop"
                            };
                            dictionary["tooltiptext"] = "Crop";
                            dictionary["filter"] = filterDict;
                            $scope.groundCoverColumns.unshift(dictionary);
                        }
                        else {
                            dictionary["thtitle"] = key;
                            dictionary["tooltiptext"] = key;
                            $scope.groundCoverColumns.push(dictionary);
                        }


                    }
                }


            });
        }

        function get_and_populate_irrigation_data() {
            IrrigationSummaryIrrigationFactory.query({
                "team": $rootScope.selected_team,
                "season": $rootScope.selected_growing_season.value,
                "selected_farm": $rootScope.selected_farm.value,
                "start_date": new Date(moment($scope.date_range.dateStart).add(12, "h").hour(12).format()),
                "end_date": $scope.date_range.dateEnd
            }, function (payload) {

                var irrigation_data = payload.data;
                $scope.IrrigationTableParams = new NgTableParams({}, {dataset: irrigation_data});
                $scope.showEmptyMessageIrrigation = (irrigation_data.length == 0);

                $scope.irrigationColumns = [];
                for (var key in irrigation_data[0]) {
                    if (irrigation_data[0].hasOwnProperty(key)) {
                        var dictionary = {
                            field: key,
                            show: true,
                            sortable: key
                        };
                        if (key == "crop") {
                            dictionary["thtitle"] = "Crop";
                            var filterDict = {};
                            filterDict[key] = {
                                id: "text",
                                placeholder: "Crop"
                            };
                            dictionary["tooltiptext"] = "Crop";
                            dictionary["filter"] = filterDict;
                            $scope.irrigationColumns.unshift(dictionary);
                        }
                        else {
                            dictionary["thtitle"] = key;
                            dictionary["tooltiptext"] = key;
                            $scope.irrigationColumns.push(dictionary);
                        }


                    }
                }


            });
        }


        $scope.irrigationSummaryCard = [{
            kh_card_content_template: "/static/assets/khapps/niabcuf/irrigation/partials/IrrigationSummaryTable.html",
            card_flex: 100,
            show_progress_bar: true
        }];

    }

})(angular);