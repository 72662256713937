angular.module("labApp").factory("SampleNewForm", function ($resource, $rootScope,
                                                            SampleLocationIds,
                                                            FCSampleIdFactory,
                                                            StSampleIdFactory,
                                                            SelectedTeamService,
                                                            $http,
                                                            FarmFiltersService) {

    var storeLots;

    function extractSampleLocationIdOptions(sample_ids) {
        return _.sortBy(_.pluck(sample_ids, 'sample_location_id'), 'name');
    }

    var sampleIdFactories = {
        field: FCSampleIdFactory,
        storage: StSampleIdFactory
    };

    var standardQueryParams = {
        team: SelectedTeamService.getSelectedSlug(),
        growing_season: FarmFiltersService.getGrowingSeason()
    };

    var forms = {
        newSampleForm: function (model) {
            return [
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            template: 'Step 1',
                            className: 'flex-100 margin-top-20'
                        },
                        {
                            template: '<md-divider></md-divider>',
                            className: 'flex-100 margin-bottom-20'
                        }

                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            className: "flex-30",
                            key: 'sample_type',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Sample type',
                                required: true,
                                defaultValue: 'field',
                                options: [
                                    {name: 'field', value: 'field'},
                                    {name: 'storage', value: 'storage'}
                                ]
                            }
                        },
                        {
                            className: "flex-30",
                            type: "datepicker",
                            key: "sample_date",
                            optionsTypes: ['pastDate'],
                            templateOptions: {
                                required: true,
                                label: "Sample date",
                                format: "DD-MM-YYYY"
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'step_one_radio',
                            type: 'radio',
                            templateOptions: {
                                inline: true,
                                label: 'Is this sample from a saved Sample Location?',
                                required: true,
                                options: [
                                    {name: 'yes', value: true},
                                    {name: 'no', value: false}
                                ]
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            template: 'Step 2',
                            className: 'flex-100 margin-top-20',
                            hideExpression: "!model.hasOwnProperty('step_one_radio')"
                        },

                        {
                            template: '<md-divider></md-divider>',
                            className: 'flex-100 margin-bottom-20',
                            hideExpression: "!model.hasOwnProperty('step_one_radio')"
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            className: 'flex-30',
                            key: 'sample_location_ref_assessment',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Assessment category',
                                required: false,
                                options: [],
                                valueProp: 'classification_name',
                                labelProp: 'classification_name'
                            },
                            hideExpression: '!model.step_one_radio',
                            controller: function ($scope, AssessmentClassification) {
                                AssessmentClassification.query(
                                    {},
                                    function (result) {
                                        $scope.classifications = result;
                                        $scope.to.options = $scope.classifications;
                                    }, function (error) {
                                    }
                                );
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'sample_location_ref',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Existing Sample Location',
                                required: false,
                                options: [],
                                valueProp: 'slug',
                                labelProp: 'name'
                            },
                            hideExpression: '!model.step_one_radio',
                            expressionProperties: {
                                "templateOptions.required": "model.step_one_radio===true",
                                "templateOptions.disabled": "model.step_one_radio===false"
                            },
                            controller: function ($scope) {
                                function fetchSampleLocations(val) {
                                    if (val && model.sample_type) {
                                        const params = {};
                                        if (SelectedTeamService.getSelectedSlug()) {
                                            params.team_slug = SelectedTeamService.getSelectedSlug();
                                        }
                                        $http.get(`/api/v1.0/lab/store-location-summary/${model.sample_type}/`,
                                            {params}).then(
                                            (resp) => {
                                           $scope.to.options = $scope.sample_ids = resp.data;
                                        });
                                    }
                                }
                                $scope.$watch("model.sample_type", fetchSampleLocations);
                                $scope.$watch("model.sample_location_ref_assessment", fetchSampleLocations);
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'farm_id',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Farm',
                                required: true,
                                disabled: false,
                                options: [],
                                labelProp: 'name',
                                valueProp: 'id'
                            },
                            hideExpression: "(!model.hasOwnProperty('sample_type')) || (model.sample_type==='storage') || (!model.hasOwnProperty('step_one_radio') || model.step_one_radio === true)",
                            controller: function ($scope) {
                                $scope.to.options = FarmFiltersService.getFarms();
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.step_one_radio===false",
                                "templateOptions.disabled": "model.step_one_radio===true || model.sample_type==='storage'"
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'plot_id', // actually the plot id, but we need the slug
                            type: 'searchable_select',
                            templateOptions: {
                                label: forms.capitalise($rootScope.platformTerminology.general.plot),
                                required: true,
                                disabled: true,
                                options: [],
                                valueProp: 'plot_id',
                                labelProp: 'long_name'
                            },
                            hideExpression: "(!model.hasOwnProperty('step_one_radio') || model.step_one_radio === true) || (!model.hasOwnProperty('sample_type')) || (model.sample_type==='storage')",
                            controller: function ($scope) {
                                $scope.plots = [];
                                $scope.$watch("$parent.model.farm_id", function handleChange(newVal, oldVal) {
                                    if (newVal) {
                                        $http.get('/api/plot/inventory/report/',
                                            {params:{farm_id: newVal, page_size: 100}}).then(
                                            (resp) => {
                                                let plots = resp.data.results.map( plot => {
                                                    if (plot.variety_name) {
                                                        plot.long_name = `${plot.field_name}-${plot.plot_name}-${plot.variety_name}-${plot.genus_name}`
                                                    } else {
                                                        plot.long_name = `${plot.field_name}-${plot.plot_name}-${plot.genus_name}`
                                                    }
                                                    return plot;
                                                });
                                                plots = _.sortBy(plots, 'long_name');
                                                $scope.to.options = $scope.plots = plots;
                                            }
                                        )
                                    }
                                });
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.farm_id && !model.sample_location_ref",
                                "templateOptions.disabled": "(!model.farm_id) || (model.sample_location_ref)"
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'store_ref',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Store name',
                                options: [],
                                required: false,
                                valueProp: 'store_slug',
                                labelProp: 'store_name'
                            },
                            hideExpression: "(!model.hasOwnProperty('step_one_radio') || model.step_one_radio === true) || (model.sample_type != 'storage')",
                            controller: function ($scope, GenericStoreFactory) {
                                GenericStoreFactory.query(function (result) {
                                    $scope.to.options = _.map(result, function(element) {
                                       return {
                                            store_slug: element.slug,
                                            store_name: element.name
                                        }
                                    });
                                })
                            },
                            expressionProperties: {
                                "templateOptions.required": "(model.sample_type === 'storage' && !model.sample_location_ref)",
                                "templateOptions.disabled": "model.sample_location_ref"
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'store_lot',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Store lot',
                                required: true,
                                disabled: true,
                                options: [],
                                valueProp: 'storelot_slug',
                                labelProp: 'name_with_plot'
                            },
                            hideExpression: "model.sample_type != 'storage' || !model.step_one_radio===false || !model.hasOwnProperty('step_one_radio')",
                            controller: function ($scope, StoreLotFactory) {
                                $scope.$watch("$parent.model.store_ref", function handleChange(newVal, oldVal) {
                                    StoreLotFactory.query({store_slug: $scope.$parent.model.store_ref}, function (lots) {
                                        $scope.to.options = lots;
                                        storeLots = lots;
                                    })
                                });
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.store_ref && !model.sample_location_ref",
                                "templateOptions.disabled": "(!model.store_ref) || (model.sample_location_ref)"
                            }
                        },

                        {
                            className: 'flex-30',
                            key: 'step_two_radio',
                            type: 'radio',
                            templateOptions: {
                                label: 'How do you wish to label this sample?',
                                options: [
                                    {name: 'Save a new sample location for future reference', value: true},
                                    {name: 'Provide a one-off description', value: false}
                                ]
                            },
                            hideExpression: "(!model.hasOwnProperty('step_one_radio') || model.step_one_radio === true)",
                            expressionProperties: {
                                "templateOptions.required": "model.hasOwnProperty('step_one_radio') && model.step_one_radio===false",
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            template: 'Step 3',
                            className: 'flex-100 margin-top-20',
                            hideExpression: "model.step_one_radio===true || !model.hasOwnProperty('step_two_radio')"
                        },
                        {
                            template: '<md-divider></md-divider>',
                            className: 'flex-100 margin-bottom-20',
                            hideExpression: "model.step_one_radio===true || !model.hasOwnProperty('step_two_radio')"
                        }

                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            className: 'flex',
                            key: 'location_code',
                            type: 'input',
                            optionsTypes: ['alNumHyphen'],
                            templateOptions: {
                                label: 'Location description (single use)',
                                disabled: false
                            },
                            hideExpression: "model.step_one_radio===true || (!model.hasOwnProperty('step_two_radio') || model.step_two_radio === true)",
                            expressionProperties: {
                                "templateOptions.disabled": "model.sample_location_ref || model.new_sample_location_id",
                                "templateOptions.required": "!model.sample_location_ref && !model.new_sample_location_id"
                            }
                        },
                        {
                            className: 'flex-30',
                            key: 'create_store_lot',
                            type: 'checkbox',
                            templateOptions: {
                                label: 'Do you wish to save a new store lot with these crop details?'
                            },
                            hideExpression: "(!model.hasOwnProperty('step_two_radio') || model.step_two_radio===true) || (model.sample_type !== 'field')"
                        },
                        {
                            className: 'flex',
                            key: 'new_store_lot_store',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Store name',
                                options: [],
                                required: false,
                                valueProp: 'store_slug',
                                labelProp: 'store_name'
                            },
                            hideExpression: "(!model.hasOwnProperty('create_store_lot') || !model.create_store_lot===true)",
                            controller: function ($scope, GenericStoreFactory) {
                                GenericStoreFactory.query(function (result) {
                                    var store_data = [];
                                    result.forEach(function (element, index, array) {
                                        store_data.push({
                                            store_slug: element.slug,
                                            store_name: element.name
                                        })
                                    });
                                    $scope.to.options = store_data;
                                })
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.create_store_lot === true",
                                "templateOptions.disabled": "!model.create_store_lot",
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            className: 'flex-40',
                            key: 'new_sample_location_assessment_category',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Assessment category (optional)',
                                required: false,
                                options: [],
                                valueProp: 'classification_name',
                                labelProp: 'classification_name'
                            },
                            hideExpression: 'model.step_one_radio===true || !model.step_two_radio || model.step_two_radio===false',
                            controller: function ($scope, AssessmentClassification) {
                                AssessmentClassification.query(
                                    {},
                                    function (result) {
                                        $scope.to.options = result;
                                    }, function (error) {
                                    }
                                );
                            }
                        },
                        {
                            className: 'flex-60',
                            key: 'new_sample_location_id',
                            type: 'input',
                            optionsTypes: ['alNumHyphen'],
                            templateOptions: {
                                label: 'New ' + $rootScope.platformTerminology.labAnalytics.sample_id + '?',
                            },
                            hideExpression: "model.step_one_radio===true || !model.hasOwnProperty('step_two_radio') || model.step_two_radio === false",
                            expressionProperties: {
                                "templateOptions.disabled": "model.sample_location_ref || model.location_code || model.step_two_radio === false",
                                "templateOptions.required": "model.step_two_radio === true"
                            }
                        }
                    ]
                },
                {
                    className: 'layout-row',
                    fieldGroup: [

                        {
                            className: 'flex-100',
                            key: 'associated_sample_location_ids',
                            type: 'searchable_select',
                            templateOptions: {
                                label: 'Does this ' + $rootScope.platformTerminology.labAnalytics.sample_id +
                                ' tie back to any field ' + $rootScope.platformTerminology.labAnalytics.sample_id + 's?',
                                required: false,
                                disabled: false,
                                multiple: true,
                                options: [],
                                valueProp: 'slug',
                                labelProp: 'name'
                            },
                            expressionProperties: {
                                "templateOptions.disabled": "!model.new_sample_location_id"
                            },
                            hideExpression: 'model.step_one_radio===true || !model.new_sample_location_id || model.sample_type==="field"',
                            controller: function ($scope) {

                                function fetchOptions() {
                                    var storelot_slug = model.store_lot;
                                    var fpc_slug = _.findWhere(storeLots, {'storelot_slug': storelot_slug}).field_plot_crop_slug;
                                    SampleLocationIds.getAssociatedSampleLocationIds(fpc_slug).then(function(slocs) {
                                        if (model.new_sample_location_assessment_category) {
                                            slocs = _.where(slocs, {assessment: model.new_sample_location_assessment_category});
                                        }
                                        $scope.to.options = slocs;
                                    });
                                }


                                $scope.$watch("$parent.model.store_lot", function(newVal, oldVal) {
                                    if (newVal) {
                                        fetchOptions();
                                    }
                                });
                                $scope.$watch("$parent.model.new_sample_location_assessment_category", function(newVal) {
                                    if (model.step_two_radio === true && newVal && model.store_lot) {
                                        fetchOptions();
                                    }
                                })

                            }
                        }

                    ]
                }
            ]
        },

        capitalise: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

    };
    return forms;
})
;
