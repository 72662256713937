(function () {

    'use strict';

    angular.module("labApp").controller(
        'SamplesListController',
        [
            '$scope',
            '$rootScope',
            function (
                $scope,
                $rootScope
            ) {
                $rootScope.page_id = "labanalytics";
                $rootScope.sub_app_page_id = "labanalytics_samplesresults";
                $rootScope.currentNavItem = 'samples';

                $scope.sampleCards = [{
                    kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/sample_list/searchable_sample_list.html',
                    card_flex: 100
                }];
            }
        ]
    );

})();
