angular.module('labApp').component('sampleLocationTableToolbar', {
    templateUrl: '/static/assets/khapps/lab/sample_locations/sample-location-table-toolbar.component.html',
    bindings: {
        onSearch: '&',
        sampleLocations: '<'
    },
    controller: function ($scope, $mdDialog) {
        var self=this;
        self.searchChanged = function() {
            self.onSearch({search: self.search});
        };
        self.showStorageNetForm = function () {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/lab/external/storage_net/storage_net_export.html',
                controller: 'StorageNetExportController'
            });
        }
    }
});
