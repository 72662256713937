(function (angular) {
    "use strict";
    angular.module("NiabCufApp.irrigation")
        .controller("IrrigationSchedulingTableController", IrrigationSchedulingTableController);

    IrrigationSchedulingTableController.$inject = ["$scope", "$rootScope", "NgTableParams", "IrrigationSchedulingFactory", "UserSettingsFactory", "DateTimeHelperFactory", "$mdDialog"];

    function IrrigationSchedulingTableController($scope, $rootScope, NgTableParams, IrrigationSchedulingFactory, UserSettingsFactory, DateTimeHelperFactory, $mdDialog) {
        var ctrl = this;
        //todo: Sort by amount
        $rootScope.page_id = "potatodashboard";
        $rootScope.sub_app_page_id = "potatodashboard_niabcuf_irrigation";
        $rootScope.view_only_irrigation = true;
        $rootScope.currentNavItem = "scheduling";


        UserSettingsFactory.get(function (apiData) {
            $scope.user_settings = apiData;
            $scope.userSettingDateFormat =
                DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment($scope.user_settings.DEFAULT_USER_READABLE_DATE_FORMAT);
        });


        $scope.parseDate = function (jsonDate) {
            if (moment(jsonDate).isValid()) {
                return moment(jsonDate).format("DD MMM YYYY");
            }
            else {
                return jsonDate;
            }

        };

        updateSchedules();

        $scope.$on("filter:selections_updated", function (obj) {
            updateSchedules();
        });

        function updateSchedules() {
            IrrigationSchedulingFactory.query(function (result_data) {
                loadTableData(result_data);

            });
        }


        function loadTableData(data) {
            $scope.showEmptyMessageIrrigationScheduling = (data.length == 0);
            $scope.schedules = data;
            $scope.tableParams = new NgTableParams({}, {dataset: $scope.schedules});
        }

        $scope.getDrilldownLink = function (field_plot_crop_slug) {

            $mdDialog.show({
                animation: true,
                templateUrl: "/static/assets/khapps/niabcuf/irrigation/partials/irrigation_scheduling_historical_data.html",
                controller: "IrrigationSchedulingHistoricalDataController",
                locals: {
                    field_plot_crop_slug: field_plot_crop_slug,
                    userSettingDateFormat: $scope.userSettingDateFormat
                }
            }).then(function (data) {
            });

            //todo: back button avoid API CALL USING SCOPE
            //IrrigationSchedulingFactory.get({field_plot_crop_slug: field_plot_crop_slug}, function (data) {
            //    loadTableData(data);
            //});
        };

        $scope.IrrigationSchedulingTable = [
            {
                kh_card_content_template: "/static/assets/khapps/niabcuf/irrigation/partials/irrigation_scheduling_table.html",
                card_flex: 100
            }
        ];

        $scope.searchLocation = {
            plot_name: {
                id: "text",
                placeholder: "Filter by plot name"
            }
        };
        $scope.searchDate = {
            store_name: {
                id: "text",
                placeholder: "Filter by date"
            }
        };
    }

})(angular);