angular.module('labApp').component('fieldSamplesTable', {
    templateUrl: '/static/assets/khapps/lab/samples_and_results/sample_list/field-samples-table.component.html',
    controller: function(FieldStoreSamplesService, KisanHubIconsResource, LabPermissionsService) {
        var self = this;
        self.service = FieldStoreSamplesService;
        self.icons = KisanHubIconsResource;
        LabPermissionsService.promise.then(function() {
            self.showAddEdit = LabPermissionsService.showAddEdit;
        })
    }
});
