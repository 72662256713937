angular.module("MessageNotificationApp").controller('MessageNotificationController',
    ["$scope", "$rootScope",
        function ($scope, $rootScope) {
            console.log("inside controller for message notification");
            $scope.$on('myCustomEvent', function (event, data) {
                console.log("inside message notification app !!!!!!!!!!!");
                console.log(data); // 'Data to send'
            });
        }
    ]);
