angular.module('labApp').controller('SamplesDetailController', function ($scope,
                                                                         $rootScope,
                                                                         $routeParams,
                                                                         GenericSampleService,
                                                                         ResultFactory,
                                                                         $mdDialog,
                                                                         $khMessage,
                                                                         LabPermissionsService,
                                                                         FieldStoreSamplesService,
                                                                         SelectedTeamService,
                                                                         FarmFiltersService,
                                                                         ErrorLoggingService,
                                                                         $filter) {

    $rootScope.page_id = "labanalytics";
    $rootScope.sub_app_page_id = "labanalytics_samplesresults";
    $rootScope.currentNavItem = null;

    $scope.deleteResult = deleteResult;
    $scope.sample_slug = $routeParams.sample_slug;
    $scope.checkPermission = LabPermissionsService.checkPermission;

    var sub = FarmFiltersService.get$().subscribe(function(filter) {
        if (filter) {
            refreshSampleData();
        }
    });

    $scope.$on('$destroy', function() {
        sub.dispose();
    });


    refreshSampleData();
    $scope.sampleDetailCards = [
        {
            card_title: 'Sample Summary',
            kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/sample_detail/sample_detail_sample_summary.html',
            card_flex: 50
        },
        {
            card_title: 'Sample Assessments Log',
            kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/sample_detail/sample_detail_result_log.html',
            kh_card_toolbar_filters_template: '/static/assets/khapps/lab/samples_and_results/sample_detail/sample_results_toolbar.html',
            card_flex: 50
        }
    ];

    $scope.newAssessmentResults = function (sample_slug) {
        $mdDialog.show({
            templateUrl: '/static/assets/khapps/lab/samples_and_results/assessment_result_add_edit/AssessmentResultForm.html',
            controller: 'AssessmentResultAddController',
            scope: $scope,
            preserveScope: true,
            locals: {
                sample_slug: sample_slug
            }
        }).then(function (data) {
            refreshSampleData();
            FieldStoreSamplesService.reload();
        });
    };
    $scope.editAssessmentResults = function (sample_slug, result_slug) {
        $mdDialog.show({
            templateUrl: '/static/assets/khapps/lab/samples_and_results/assessment_result_add_edit/AssessmentResultForm.html',
            controller: 'AssessmentResultEditController',
            scope: $scope,
            preserveScope: true,
            locals: {
                sample_slug: sample_slug,
                result_slug: result_slug
            }
        });
    };
    $scope.viewAssessmentResultDetails = function (result_slug) {
        $mdDialog.show({
            templateUrl: '/static/assets/khapps/lab/samples_and_results/assessment_result_detail/AssessmentResultDetail.html',
            controller: 'AssessmentResultDetailController',
            locals: {
                result_slug: result_slug
            }
        });
    };


    function refreshSampleData() {
        GenericSampleService.get($scope.sample_slug).then(function (sample) {
            $scope.sample_detail = sample;
        }, ErrorLoggingService.genericAPIError);
    }

    function deleteResult(resultSlug) {
        ResultFactory.delete(
            resultSlug,
            function (success) {
                $khMessage.show($filter('translate')('deleted_successfully'), 5000);
                refreshSampleData();
            }, function (error) {
                $khMessage.show($filter('translate')('failed_to_delete'), 5000);
            }
        );
    }

    $scope.convertEvaluation = function (assessment_status) {
        if (assessment_status === 1) {
            return 'Pass'
        }
        if (assessment_status === 0) {
            return 'Fail'
        }
    };

});



