/**
 * Field and store samples service
 */
angular.module('labApp').service('FieldStoreSamplesService', function($khMessage,
                                                                 $filter,
                                                                 ErrorLoggingService,
                                                                 SelectedTeamService,
                                                                 FarmFiltersService,
                                                                 StorageSampleFactory,
                                                                 FcSampleFactory) {

    var self=this;

    self.field = {
        pageSize: 20,
        page: 1,
        samples: null,
        factory: FcSampleFactory
    };
    self.store = {
        pageSize: 20,
        page: 1,
        samples: null,
        factory: StorageSampleFactory
    };
    self.searchParams = {};

    self.searchParamChange = function (type) {
        // Resetting page num when filter param entered for fresh pull of data sets
        self[type].page = 1;
        reload(type);
    };

    self.pageChanged = function(type, page, pageSize) {
        self[type].page = page;
        self[type].pageSize = pageSize;
        reload(type);
    };

    self.reloadField = function() {
        reload('field')
    };

    self.reloadStore = function() {
        reload('store')
    };

    self.reload = function() {
        self.reloadField();
        self.reloadStore();
    };

    function reload(type) {
        var params = {
            team: SelectedTeamService.getSelectedSlug(),
            growing_season: FarmFiltersService.getGrowingSeason()
        };

        if (self[type].page) {
            params['page'] = self[type].page;
        }
        params = Object.assign({}, params, self.searchParams);

        self[type].samples = null;
        self[type].factory.get(params, function(data) {
            _.assign(self[type], {
                total: data.total_count,
                samples: data.data,
                loaded: true
            });
        }, function() {
            $khMessage.show($filter('translate')('data_loading_error'));
        });
    }

    self.deleteSample = function(type, slug) {
        self[type].factory.delete({slug:slug}, function() {
            reload(type)
        },function() {
            $khMessage.show($filter('translate')('delete_failure'));
        });
    };

    FarmFiltersService.get$().subscribe(navBarFilterChanged);
    SelectedTeamService.getTeamSlug$().subscribe(navBarFilterChanged);

    function navBarFilterChanged(filter) {
        if (filter && FarmFiltersService.getGrowingSeason()) {
            self.reloadField();
            self.reloadStore();
        }
    }
});
