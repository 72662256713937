(function () {

    'use strict';

    angular.module("labApp").controller('AssessmentResultDetailController',
        [
            '$scope',
            '$mdDialog',
            'result_slug',
            'ResultFactory',
            function ($scope,
                      $mdDialog,
                      result_slug,
                      ResultFactory) {

                $scope.resultDetailCards = [
                    {
                        card_title: 'Result Summary',
                        kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/assessment_result_detail/result_detail_summary.html',
                        card_flex: 100
                    },
                    {
                        card_title: 'Assessment Values',
                        kh_card_content_template: '/static/assets/khapps/lab/samples_and_results/assessment_result_detail/result_assessment_values.html',
                        card_flex: 100
                    }
                ];
                $scope.result_slug = result_slug;
                ResultFactory.get(
                    $scope.result_slug,
                    {},
                    function (result) {
                        $scope.results = result.results;
                        $scope.sample_detail = result.sample_detail;

                        $scope.param_name_dict = {};
                        $scope.results.assessment_values.forEach(function (av_element, av_index, av_array) {
                            var pr = av_element.param_ref;
                            $scope.results.assessment_config_ref.parameters_ref_list.forEach(function (pr_element, pr_index, pr_array) {
                                if (pr_element._id.$oid === pr.$oid) {
                                    $scope.param_name_dict[pr.$oid] = pr_element.form_label;
                                }
                            })
                        })
                    },
                    function (error) {
                        console.log("error: ", error);
                    }
                );


            }
        ]
    );

})();
